import { useCallback, useState } from "react";

const useActiveSection = () => {
    const [activeSection, setActiveSection] = useState("demand");

    const handleActiveSection = useCallback(
        (id: string) => setActiveSection(id),
        [setActiveSection]
    );

    return { handleActiveSection, activeSection };
};

export default useActiveSection;
