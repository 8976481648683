import { useEffect, useRef, useState } from "react";
import { BarLegend, ChannelsLegend } from "components/Map/Legend/Gradient";
import "leaflet/dist/leaflet.css";
import Loader from "components/Loader";
import { GeoJSON, MapContainer } from "react-leaflet";
import { TooltipInfo } from "types/tooltip-info.types";
import ToolTip from "components/ToolTip";
import { centerMap } from "utils/map";
import { distances } from "utils/items";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const MapSection = ({
    section,
    data,
    isLoading,
    kilometers,
    districtId
}: {
    section: string;
    data: any;
    isLoading: boolean;
    kilometers?: string;
    districtId?: number;
}) => {
    const [mapRef, setMapRef] = useState<any>(null);
    const geoJsonLayer = useRef<any>(null);
    const [tooltipInfo, setTooltipInfo] = useState<TooltipInfo | null>(null);

    const highlightFeature = (layer: any) => setTooltipInfo(layer);

    const resetHighlight = () => setTooltipInfo(null);

    const mapInteractions = (_: any, layer: any) =>
        layer.on({
            mouseout: resetHighlight,
            mouseover: highlightFeature
        });

    useEffect(() => {
        if (districtId && mapRef && geoJsonLayer) {
            mapRef.target.invalidateSize();
            mapRef.target.flyToBounds(
                Object.values(geoJsonLayer.current._layers).find(
                    ({ feature }: any) => feature.divisionCode === districtId
                )
            );
        }
    }, [data, districtId, mapRef]);

    return (
        <div tw="bg-white-0 h-full rounded relative">
            {isLoading ? (
                <Loader />
            ) : (
                <MapContainer
                    tw="h-full bg-[#FAF8F9]"
                    zoomSnap={0.5}
                    zoom={5.5}
                    center={centerMap}
                    minZoom={5.5}
                    whenReady={setMapRef as any}
                >
                    <GeoJSON
                        ref={geoJsonLayer}
                        style={(item: any) => ({
                            fillColor:
                                section === "impact"
                                    ? "#173D93"
                                    : item.fillColor,
                            fillOpacity: 1,
                            weight: 2.5,
                            color: "white"
                        })}
                        data={data}
                        onEachFeature={mapInteractions}
                    />
                </MapContainer>
            )}
            {tooltipInfo && <ToolTip tooltipInfo={tooltipInfo as any} />}
            {section === "demand" ? (
                <BarLegend />
            ) : section === "channels" ? (
                <ChannelsLegend
                    kilometers={
                        distances.find(({ id }) => id === kilometers)!.name
                    }
                />
            ) : null}
        </div>
    );
};

export default MapSection;
