import { InfoTablesProps } from "types/common-table.types";
import { TableRow } from "types/table.types";
import { rankTablesText } from "utils/items";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const InfoTables = ({
    title,
    tableData,
    regionId,
    districtId
}: InfoTablesProps) => {
    const rows: any =
        regionId && districtId
            ? [
                  {
                      label: districtId,
                      value: tableData.womenSelectedDistrict
                  },
                  {
                      label: regionId,
                      value: tableData.womenSelectedDivision
                  },
                  {
                      label: "Pakistan",
                      value: tableData.totalWomen
                  }
              ]
            : Object.entries(tableData).map(([key, value]) => ({
                  label: rankTablesText.find(({ id }) => id === key)!.name,
                  value
              }));

    return (
        <div tw="flex flex-col">
            <h2 tw="leading-[16px] py-2 text-xs tracking-[5px]">{title}</h2>
            {rows.map(({ label, value }: TableRow) => (
                <div
                    tw="flex justify-between items-center py-2 text-[14px] border-b-[0.5px]  border-gray-200"
                    key={label}
                >
                    <h3 tw="max-w-[15rem]">{label}</h3>
                    <span tw="text-blue-400 font-bold">
                        {value?.toLocaleString("en-US")}
                    </span>
                </div>
            ))}
        </div>
    );
};

export default InfoTables;
