import "App.css";
import ErrorBoundary from "components/ErrorBoundary";
import PakistanNutrition from "layouts";

const App = () => (
    <ErrorBoundary>
        <PakistanNutrition />
    </ErrorBoundary>
);

export default App;
