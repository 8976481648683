import Header from "components/Header";
import useActiveSection from "hooks/useActiveSection";
import MapSection from "components/Map";
import LayoutGrid from "layouts/Grid";
import Demand from "components/Main/Demand";
import Channels from "components/Main/Channels";
import Targeting from "components/Main/Targeting";
import ImpactTables from "components/Main/Impact/Tables";
import ImpactFilters from "components/Main/Impact/Filters";
import { useCallback, useMemo, useState } from "react";
import useFetch from "hooks/useFetch";
import Loader from "components/Loader";
import { regionsDistricts } from "utils/items";
import { filterDistricts } from "utils/utilities";
import { urls } from "config/urls";

const INITIAL_FILTERS = {
    demandFilters: {
        amongWomenFilter: "PREGNANT",
        areaFilter: "BOTH",
        wealthFilter: ["Q1", "Q2", "Q3", "Q4", "Q5"],
        geographyFilterDistrictId: "766",
        geographyFilterRegionId: "7",
        spendingDailyFilters: ["ZERO__TWO"],
        ageGroupFilters: ["FIFTEEN__TWENTY"]
    },
    channelsFilters: {
        amongWomenFilter: "CHILDBEARING",
        liveWithinFilter: "TEN",
        storeTypeFilter: "ANY",
        geographyFilterDistrictId: "766",
        geographyFilterRegionId: "7",
        areaFilter: "BOTH",
        showAgentTotal: false
    },
    targetingFilters: {
        liveWithinFilter: "THREE",
        storeTypeFilter: "SUPERMARKET",
        areaFilter: "BOTH",
        wealthFilter: ["Q1", "Q2", "Q3", "Q4", "Q5"]
    },
    impactFilters: {
        unitsPerMonth: 1,
        costPerUnit: 3,
        xProgramReach: 1.5,
        caseAverted: 1,
        antenatalCare: 1.5,
        geographyFilterDistrictId: "766",
        includeLactatingWomen: true,
        ageGroupFilters: ["FIFTEEN__TWENTY"],
        wealthFilter: ["Q1", "Q2", "Q3", "Q4", "Q5"],
        coverage: 50,
        successRate: 50,
        urbanClassification: 50,
        antenatalCareAccess: 50
    }
};

const PakistanNutrition = () => {
    const { activeSection, handleActiveSection } = useActiveSection();
    const [filters, setFilters] = useState<any>(INITIAL_FILTERS);

    const body: any = useMemo(
        () => ({
            demand: {
                demandFilterRequest: {
                    amongWomenFilter: filters.demandFilters.amongWomenFilter,
                    ageGroupFilters: filters.demandFilters.ageGroupFilters,
                    wealthFilter: filters.demandFilters.wealthFilter,
                    spendingDailyFilters:
                        filters.demandFilters.spendingDailyFilters,
                    geographyFilter: {
                        districtId:
                            filters.demandFilters.geographyFilterDistrictId,
                        divisionId:
                            filters.demandFilters.geographyFilterRegionId
                    },
                    areaFilter: filters.demandFilters.areaFilter
                }
            },
            channels: {
                channelFilterRequest: {
                    amongWomenFilter: filters.channelsFilters.amongWomenFilter,
                    liveWithinFilter: filters.channelsFilters.liveWithinFilter,
                    storeTypeFilter: filters.channelsFilters.storeTypeFilter,
                    geographyFilter: {
                        districtId:
                            filters.channelsFilters.geographyFilterDistrictId,
                        divisionId:
                            filters.channelsFilters.geographyFilterRegionId
                    },
                    areaFilter: filters.channelsFilters.areaFilter,
                    showAgentTotal: filters.channelsFilters.showAgentTotal
                }
            },
            targeting: {
                targetingFilterRequest: {
                    liveWithinFilter: filters.targetingFilters.liveWithinFilter,
                    storeTypeFilter: filters.targetingFilters.storeTypeFilter,
                    areaFilter: filters.targetingFilters.areaFilter,
                    wealthFilter: filters.targetingFilters.wealthFilter
                }
            },
            impact: {
                geographyFilter: {
                    districtId: filters.impactFilters.geographyFilterDistrictId
                },
                includeLactatingWomen:
                    filters.impactFilters.includeLactatingWomen,
                ageGroupFilters: filters.impactFilters.ageGroupFilters,
                wealthFilter: filters.impactFilters.wealthFilter,
                percentageFilter: {
                    coverage: filters.impactFilters.coverage,
                    successRate: filters.impactFilters.successRate,
                    urbanClassification:
                        filters.impactFilters.urbanClassification,
                    antenatalCareAccess:
                        filters.impactFilters.antenatalCareAccess
                },
                weightedDistrictRanking: 2.5,
                unitsPerMonth: filters.impactFilters.unitsPerMonth,
                costPerUnit: filters.impactFilters.costPerUnit,
                xProgramReach: filters.impactFilters.xProgramReach,
                caseAverted: filters.impactFilters.caseAverted,
                antenatalCare: filters.impactFilters.antenatalCare
            }
        }),
        [filters]
    );

    const { data, isLoading } = useFetch(
        `${urls.API_HOST_URL}/geo-data`,
        activeSection === "impact",
        body[activeSection],
        activeSection
    );

    const { data: impactData, isLoading: isLoadingImpactData } = useFetch(
        `${urls.API_HOST_URL}/impact`,
        activeSection !== "impact",
        body[activeSection],
        activeSection
    );

    const { data: tableChartData, isLoading: isLoadingChart } = useFetch(
        `${urls.API_HOST_URL}/chart`,
        false,
        body[activeSection],
        activeSection
    );

    const handleSelect = useCallback(
        ({
            target
        }: {
            target: { name: string; id: string; value: string };
        }) => {
            if (target.name === "geographyFilterRegionId") {
                setFilters({
                    ...filters,
                    [`${activeSection}Filters`]: {
                        ...filters[`${activeSection}Filters`],
                        [target.name]: target.id || target.value,
                        geographyFilterDistrictId: filterDistricts(
                            regionsDistricts,
                            target.id || target.value
                        )[0].id
                    }
                });
            } else {
                setFilters({
                    ...filters,
                    [`${activeSection}Filters`]: {
                        ...filters[`${activeSection}Filters`],
                        [target.name]: target.id || target.value
                    }
                });
            }
        },
        [activeSection, filters]
    );

    const handleCheckbox = useCallback(
        ({ target }: { target: { name: string; checked: boolean } }) =>
            setFilters({
                ...filters,
                [`${activeSection}Filters`]: {
                    ...filters[`${activeSection}Filters`],
                    [target.name]: target.checked
                }
            }),
        [activeSection, filters]
    );

    const handleInput = useCallback(
        (name: string, value: number) =>
            setFilters({
                ...filters,
                impactFilters: {
                    ...filters.impactFilters,
                    [name]: value
                }
            }),
        [filters]
    );

    const handlePrev = useCallback(
        (filterName: string, isFromInput: boolean) =>
            setFilters((prevState: any) => ({
                ...prevState,
                impactFilters: {
                    ...prevState.impactFilters,
                    [filterName]:
                        Number(prevState.impactFilters[filterName]) -
                        (isFromInput ? 0.5 : 1)
                }
            })),
        []
    );

    const handleNext = useCallback(
        (filterName: string, isFromInput: boolean) =>
            setFilters((prevState: any) => ({
                ...prevState,
                impactFilters: {
                    ...prevState.impactFilters,
                    [filterName]:
                        Number(prevState.impactFilters[filterName]) +
                        (isFromInput ? 0.5 : 1)
                }
            })),
        []
    );

    return (
        <>
            <Header
                handleActiveSection={handleActiveSection}
                activeSection={activeSection}
            />
            <main>
                {isLoading && isLoadingChart && isLoadingImpactData ? (
                    <Loader />
                ) : (
                    <LayoutGrid
                        leftColumn={
                            activeSection === "demand" ? (
                                <Demand
                                    handleSelect={handleSelect}
                                    filters={filters.demandFilters}
                                    isLoadingChart={isLoadingChart}
                                    data={tableChartData}
                                />
                            ) : activeSection === "channels" ? (
                                <Channels
                                    handleSelect={handleSelect}
                                    handleCheckbox={handleCheckbox}
                                    filters={filters.channelsFilters}
                                    isLoadingChart={isLoadingChart}
                                    tableData={tableChartData}
                                />
                            ) : activeSection === "targeting" ? (
                                <Targeting
                                    handleSelect={handleSelect}
                                    filters={filters.targetingFilters}
                                    kilometers={
                                        filters.targetingFilters
                                            .liveWithinFilter
                                    }
                                />
                            ) : (
                                <ImpactFilters
                                    handleSelect={handleSelect}
                                    handleCheckbox={handleCheckbox}
                                    handleInput={handleInput}
                                    handlePrev={handlePrev}
                                    handleNext={handleNext}
                                    filters={filters.impactFilters}
                                />
                            )
                        }
                        middleColumn={
                            activeSection === "impact" && (
                                <MapSection
                                    section={activeSection}
                                    data={data}
                                    isLoading={isLoading}
                                    districtId={Number(
                                        filters.impactFilters
                                            .geographyFilterDistrictId
                                    )}
                                />
                            )
                        }
                        rightColumn={
                            activeSection === "impact" &&
                            isLoadingImpactData ? null : activeSection ===
                                  "impact" && !isLoadingImpactData ? (
                                <ImpactTables tableData={impactData} />
                            ) : (
                                <MapSection
                                    section={activeSection}
                                    data={data}
                                    isLoading={isLoading}
                                    kilometers={
                                        filters.channelsFilters.liveWithinFilter
                                    }
                                />
                            )
                        }
                    />
                )}
            </main>
        </>
    );
};

export default PakistanNutrition;
