import { useCallback, useEffect, useState } from "react";
import { handleColor } from "utils/map";

const useFetch = (
    url: string,
    skip = false,
    body: any,
    activeSection: string
) => {
    const [data, setData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const fetchData = useCallback(async () => {
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        };
        if (skip) return;
        setIsLoading(true);
        try {
            const response = await fetch(url, options);
            const data = await response.json();
            if (response.ok) {
                if (Array.isArray(data))
                    setData(
                        data.map((item: any) => ({
                            ...item,
                            fillColor: handleColor(
                                activeSection,
                                item.filterData.mapWomenData
                                    .filteredWomenPercent,
                                item.filterData.mapWomenData
                                    .pregnantWomenAreUndernourished,
                                item.filterData.mapWomenData
                                    .pregnantWomenLiveWithin
                            )
                        }))
                    );
                else setData(data);
            } else {
                setHasError(true);
                setErrorMessage(data);
            }
        } catch (err: any) {
            setHasError(true);
            setErrorMessage(err.message);
        } finally {
            setIsLoading(false);
        }
    }, [url, skip, body, activeSection]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return { data, isLoading, hasError, errorMessage };
};

export default useFetch;
