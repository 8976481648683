import { CommonKeysPair } from "types/common-key-pair.types";

export const sectionButtons: CommonKeysPair[] = [
    { name: "Demand", id: "demand" },
    { name: "Channels", id: "channels" },
    { name: "Targeting", id: "targeting" },
    { name: "Impact", id: "impact" }
];
export const agents: CommonKeysPair[] = [
    { id: "ANY", name: "all" },
    { id: "PHARMACY", name: "pharmacy" },
    { id: "SUPERMARKET", name: "supermarket" },
    { id: "UTILITY_STORE", name: "utility store" }
];

export const womenDemandGroups: CommonKeysPair[] = [
    { name: "all", id: "ALL" },
    { name: "pregnant women", id: "PREGNANT" },
    { name: "childbearing age", id: "CHILDBEARING" }
];

export const spendingGroups: CommonKeysPair[] = [
    { name: "0-2", id: "ZERO__TWO" },
    { name: "2-8", id: "TWO__EIGHT" },
    { name: "8-11", id: "EIGHT__ELEVEN" },
    { name: "11-20", id: "ELEVEN__TWENTY" },
    { name: "20-30", id: "TWENTY__THIRTY" },
    { name: "30-50", id: "THIRTY__FIFTY" },
    { name: "50-70", id: "FIFTY__SEVENTY" },
    { name: "70-90", id: "SEVENTY__NINETY" },
    { name: "90-110", id: "NINETY__ONE_HUNDRED_AND_TEN" },
    { name: "+110", id: "ONE_HUNDRED_AND_TEN__INF" }
];

export const ageGroups: CommonKeysPair[] = [
    { name: "0-5", id: "ZERO__FIVE" },
    { name: "05-10", id: "FIVE__TEN" },
    { name: "10-15", id: "TEN__FIFTEEN" },
    { name: "15-20", id: "FIFTEEN__TWENTY" },
    { name: "20-25", id: "TWENTY__TWENTY_FIVE" },
    { name: "25-30", id: "TWENTY_FIVE__THIRTY" },
    { name: "30-35", id: "THIRTY__THIRTY_FIVE" },
    { name: "35-40", id: "THIRTY_FIVE__FORTY" },
    { name: "40-45", id: "FORTY__FORTY_FIVE" },
    { name: "45-50", id: "FORTY_FIVE__FIFTY" },
    { name: "50-55", id: "FIFTY__FIFTY_FIVE" },
    { name: "55-60", id: "FIFTY_FIVE__SIXTY" },
    { name: "60-65", id: "SIXTY__SIXTY_FIVE" },
    { name: "65-70", id: "SIXTY_FIVE__SEVENTY" },
    { name: "70-75", id: "SEVENTY__SEVENTY_FIVE" },
    { name: "+75", id: "SEVENTY_FIVE__INF" }
];

export const wealthGroups: CommonKeysPair[] = [
    { id: "Q1", name: "Q1" },
    { id: "Q2", name: "Q2" },
    { id: "Q3", name: "Q3" },
    { id: "Q4", name: "Q4" },
    { id: "Q5", name: "Q5" }
];

export const regionGroups: CommonKeysPair[] = [
    { id: "BOTH", name: "urban and rural" },
    { id: "RURAL", name: "rural" },
    { id: "URBAN", name: "urban" }
];

export const distances: CommonKeysPair[] = [
    { id: "ONE", name: "1km" },
    { id: "THREE", name: "3km" },
    { id: "FIVE", name: "5km" },
    { id: "TEN", name: "10km" }
];

export const regionsDistricts: CommonKeysPair[] = [
    {
        id: "8",
        name: "Sindh",
        divisions: [
            {
                id: "83",
                name: "Karachi"
            },
            {
                id: "81",
                name: "Banbhore"
            },
            {
                id: "82",
                name: "Hyderabad"
            },
            {
                id: "84",
                name: "Sukkur"
            },
            {
                id: "85",
                name: "Larkana"
            },
            {
                id: "86",
                name: "Mirpur Khas"
            },
            {
                id: "87",
                name: "Shaheed Benazirabad"
            }
        ],
        districts: [
            {
                id: "101",
                name: "Karachi Central"
            },
            {
                id: "102",
                name: "Karachi East"
            },
            {
                id: "103",
                name: "Karachi South"
            },
            {
                id: "104",
                name: "Karachi West"
            },
            {
                id: "105",
                name: "Malir"
            },
            {
                id: "811",
                name: "Badin"
            },
            {
                id: "812",
                name: "Thatta"
            },
            {
                id: "821",
                name: "Dadu"
            },
            {
                id: "822",
                name: "Hyderabad"
            },
            {
                id: "823",
                name: "Jamshoro"
            },
            {
                id: "824",
                name: "Matiari"
            },
            {
                id: "825",
                name: "Tando Allah Yar"
            },
            {
                id: "826",
                name: "Tando Muhammad Khan"
            },
            {
                id: "841",
                name: "Ghotki"
            },
            {
                id: "842",
                name: "Khairpur"
            },
            {
                id: "843",
                name: "Sukkur"
            },
            {
                id: "851",
                name: "Jacobabad"
            },
            {
                id: "852",
                name: "Kashmore"
            },
            {
                id: "853",
                name: "Larkana"
            },
            {
                id: "854",
                name: "Shahdadkot"
            },
            {
                id: "855",
                name: "Shikarpur"
            },
            {
                id: "861",
                name: "Mir Pur Khas"
            },
            {
                id: "862",
                name: "Tharparkar"
            },
            {
                id: "863",
                name: "Umer Kot"
            },
            {
                id: "871",
                name: "Nowshero Feroze"
            },
            {
                id: "872",
                name: "Sanghar"
            },
            {
                id: "873",
                name: "Shaheed Benazirabad"
            }
        ]
    },
    {
        id: "2",
        name: "Khyber Pakhtunkhwa",
        divisions: [
            {
                id: "20",
                name: "Bannu"
            },
            {
                id: "21",
                name: "Dera Ismail Khan"
            },
            {
                id: "22",
                name: "Hazara"
            },
            {
                id: "23",
                name: "Kohat"
            },
            {
                id: "24",
                name: "Malakand"
            },
            {
                id: "25",
                name: "Mardan"
            },
            {
                id: "26",
                name: "Peshawar"
            }
        ],
        districts: [
            {
                id: "201",
                name: "Bannu"
            },
            {
                id: "202",
                name: "Lakki Marwat"
            },
            {
                id: "203",
                name: "North Waziristan"
            },
            {
                id: "204",
                name: "FR Bannu"
            },
            {
                id: "205",
                name: "FR Lakki Marwat"
            },
            {
                id: "211",
                name: "D. I. Khan"
            },
            {
                id: "212",
                name: "Tank"
            },
            {
                id: "213",
                name: "South Waziristan"
            },
            {
                id: "214",
                name: "FR Dera Ismail Khan"
            },
            {
                id: "215",
                name: "FR Tank"
            },
            {
                id: "221",
                name: "Abbottabad"
            },
            {
                id: "222",
                name: "Batagram"
            },
            {
                id: "223",
                name: "Haripur"
            },
            {
                id: "224",
                name: "Kohistan"
            },
            {
                id: "225",
                name: "Manshera"
            },
            {
                id: "226",
                name: "Tor Garh"
            },
            {
                id: "231",
                name: "Hangu"
            },
            {
                id: "232",
                name: "Karak"
            },
            {
                id: "233",
                name: "Kohat"
            },
            {
                id: "234",
                name: "Kurram"
            },
            {
                id: "235",
                name: "Orakzai"
            },
            {
                id: "236",
                name: "FR Kohat"
            },
            {
                id: "241",
                name: "Bonair"
            },
            {
                id: "242",
                name: "Chitral"
            },
            {
                id: "243",
                name: "Lower Dir"
            },
            {
                id: "244",
                name: "Malakand"
            },
            {
                id: "245",
                name: "Shangla"
            },
            {
                id: "246",
                name: "Swat"
            },
            {
                id: "247",
                name: "Upper Dir"
            },
            {
                id: "248",
                name: "Bajaur"
            },
            {
                id: "251",
                name: "Mardan"
            },
            {
                id: "252",
                name: "Swabi"
            },
            {
                id: "261",
                name: "Charsada"
            },
            {
                id: "262",
                name: "Nowsehra"
            },
            {
                id: "263",
                name: "Peshawar"
            },
            {
                id: "264",
                name: "Khyber"
            },
            {
                id: "265",
                name: "Mohmand"
            },
            {
                id: "266",
                name: "FR Peshawar"
            }
        ]
    },
    {
        id: "5",
        name: "Federal Capital Territory",
        divisions: [
            {
                id: "51",
                name: "Islamabad"
            }
        ],
        districts: [
            {
                id: "511",
                name: "Islamabad"
            }
        ]
    },
    {
        id: "6",
        name: "Punjab",
        divisions: [
            {
                id: "61",
                name: "Bahawalpur"
            },
            {
                id: "62",
                name: "Dera Ghazi Khan"
            },
            {
                id: "63",
                name: "Faisalabad"
            },
            {
                id: "64",
                name: "Gujranwala"
            },
            {
                id: "65",
                name: "Lahore"
            },
            {
                id: "66",
                name: "Multan"
            },
            {
                id: "67",
                name: "Rawalpindi"
            },
            {
                id: "68",
                name: "Sahiwal"
            },
            {
                id: "69",
                name: "Sargodha"
            }
        ],
        districts: [
            {
                id: "611",
                name: "Bahawalnagar"
            },
            {
                id: "612",
                name: "Bahawalpur"
            },
            {
                id: "613",
                name: "Rahim Yar Khan"
            },
            {
                id: "621",
                name: "D. G. Khan"
            },
            {
                id: "622",
                name: "Layyah"
            },
            {
                id: "623",
                name: "Muzaffar Garh"
            },
            {
                id: "624",
                name: "Rajanpur"
            },
            {
                id: "631",
                name: "Chiniot"
            },
            {
                id: "632",
                name: "Faisalabad"
            },
            {
                id: "633",
                name: "Jhang"
            },
            {
                id: "634",
                name: "T.T. Singh"
            },
            {
                id: "641",
                name: "Gujranwala"
            },
            {
                id: "642",
                name: "Gujrat"
            },
            {
                id: "643",
                name: "Hafizabad"
            },
            {
                id: "644",
                name: "Mandi Bahuddin"
            },
            {
                id: "645",
                name: "Narowal"
            },
            {
                id: "646",
                name: "Sialkot"
            },
            {
                id: "651",
                name: "Kasur"
            },
            {
                id: "652",
                name: "Lahore"
            },
            {
                id: "653",
                name: "Nankana Sahib"
            },
            {
                id: "654",
                name: "Sheikhupura"
            },
            {
                id: "661",
                name: "Khanewal"
            },
            {
                id: "662",
                name: "Lodhran"
            },
            {
                id: "663",
                name: "Multan"
            },
            {
                id: "664",
                name: "Vehari"
            },
            {
                id: "671",
                name: "Attock"
            },
            {
                id: "672",
                name: "Chakwal"
            },
            {
                id: "673",
                name: "Jehlum"
            },
            {
                id: "674",
                name: "Rawalpindi"
            },
            {
                id: "681",
                name: "Okara"
            },
            {
                id: "682",
                name: "Pakpatten"
            },
            {
                id: "683",
                name: "Sahiwal"
            },
            {
                id: "691",
                name: "Bhakhar"
            },
            {
                id: "692",
                name: "Khushab"
            },
            {
                id: "693",
                name: "Mianwali"
            },
            {
                id: "694",
                name: "Sargodha"
            }
        ]
    },
    {
        id: "7",
        name: "Balochistan",
        divisions: [
            {
                id: "71",
                name: "Kalat"
            },
            {
                id: "72",
                name: "Makran"
            },
            {
                id: "73",
                name: "Nasirabad"
            },
            {
                id: "74",
                name: "Quetta"
            },
            {
                id: "75",
                name: "Sibi"
            },
            {
                id: "76",
                name: "Zhob"
            },
            {
                id: "77",
                name: "Rakhshan"
            }
        ],
        districts: [
            {
                id: "711",
                name: "Awaran"
            },
            {
                id: "712",
                name: "Kalat"
            },
            {
                id: "713",
                name: "Khuzdar"
            },
            {
                id: "714",
                name: "Lasbela"
            },
            {
                id: "715",
                name: "Mastung"
            },
            {
                id: "721",
                name: "Gwader"
            },
            {
                id: "722",
                name: "Ketch/Turbat"
            },
            {
                id: "723",
                name: "Panjgoor"
            },
            {
                id: "731",
                name: "Jaffarabad"
            },
            {
                id: "732",
                name: "Jhal Magsi"
            },
            {
                id: "733",
                name: "Bolan/Kachhi"
            },
            {
                id: "734",
                name: "Nasirabad/Tamboo"
            },
            {
                id: "735",
                name: "Sohbatpur"
            },
            {
                id: "741",
                name: "Qilla Abdullah"
            },
            {
                id: "742",
                name: "Pishine"
            },
            {
                id: "743",
                name: "Quetta"
            },
            {
                id: "751",
                name: "Dera Bugti"
            },
            {
                id: "752",
                name: "Harnai"
            },
            {
                id: "753",
                name: "Kohlu"
            },
            {
                id: "754",
                name: "Sibbi"
            },
            {
                id: "755",
                name: "Ziarat"
            },
            {
                id: "756",
                name: "Lehri"
            },
            {
                id: "761",
                name: "Barkhan"
            },
            {
                id: "762",
                name: "Qilla Saifullah"
            },
            {
                id: "763",
                name: "Loralai"
            },
            {
                id: "764",
                name: "Musa Khel"
            },
            {
                id: "765",
                name: "Sherani"
            },
            {
                id: "766",
                name: "Zhob"
            },
            {
                id: "771",
                name: "Chaghai"
            },
            {
                id: "772",
                name: "Kharan"
            },
            {
                id: "773",
                name: "Nushki"
            },
            {
                id: "774",
                name: "Washuk"
            }
        ]
    },
    {
        id: "10",
        name: "Northern Areas",
        divisions: [
            {
                id: "100",
                name: "Northern Areas"
            }
        ],
        districts: [
            {
                id: "901",
                name: "Kargil"
            },
            {
                id: "902",
                name: "Kupwara (Gilgit Wazarat)"
            },
            {
                id: "903",
                name: "Ladakh (Leh)"
            },
            {
                id: "912",
                name: "Chilas"
            },
            {
                id: "913",
                name: "Gilgit"
            },
            {
                id: "914",
                name: "Gilgit (Tribal Territory)"
            }
        ]
    },
    {
        id: "9",
        name: "Azad Kashmir",
        divisions: [
            {
                id: "90",
                name: "Azad Kashmir"
            }
        ],
        districts: [
            {
                id: "904",
                name: "Bagh"
            },
            {
                id: "905",
                name: "Bhimber"
            },
            {
                id: "906",
                name: "Kotli"
            },
            {
                id: "907",
                name: "Mirpur"
            },
            {
                id: "908",
                name: "Muzaffarabad"
            },
            {
                id: "909",
                name: "Neelum"
            },
            {
                id: "910",
                name: "Poonch"
            },
            {
                id: "911",
                name: "Sudhnati"
            }
        ]
    }
];

export const rankTablesText: CommonKeysPair[] = [
    {
        id: "totalWomenPregnantTargeted",
        name: "Total pregnant women targeted"
    },
    {
        id: "totalWomenPregnantReached",
        name: "Total pregnant women reached"
    },
    {
        id: "undernourishedPregnantWomenTargeted",
        name: "Undernourished pregnant women targeted"
    },
    {
        id: "undernourishedPregnantWomenReached",
        name: "Undernourished pregnant women reached"
    },
    {
        id: "totalCostPKR",
        name: "Total cost (PKR)"
    },
    {
        id: "totalCostUSD",
        name: "Total cost (USD)"
    },
    {
        id: "maternalCasesAverted",
        name: "Cases of maternal undernutrition averted"
    },
    {
        id: "costPerCaseAverted",
        name: "Cost per case averted (USD)"
    }
];
