import InfoTables from "components/Table/InfoTables";
import Dropdown from "components/Dropdown";
import {
    agents,
    distances,
    regionGroups,
    womenDemandGroups,
    regionsDistricts
} from "utils/items";
import {
    currentValueName,
    filterDistricts,
    filterOptions
} from "utils/utilities";
import { TableDataTypes } from "types/table-chart-data.types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Channels = ({
    handleSelect,
    handleCheckbox,
    filters,
    isLoadingChart,
    tableData
}: {
    handleSelect: any;
    handleCheckbox: any;
    filters: any;
    isLoadingChart: boolean;
    tableData: TableDataTypes;
}) => {
    const { womenSelectedDistrict, womenSelectedDivision, totalWomen } =
        tableData;

    return (
        <div tw="grid grid-rows-[auto 1fr] items-center h-full text-sm">
            <div tw="p-[1rem]">
                <div tw="flex items-center gap-[0.5rem] flex-wrap">
                    Among
                    <Dropdown
                        options={filterOptions(
                            womenDemandGroups,
                            filters.amongWomenFilter
                        )}
                        currentValue={currentValueName(
                            womenDemandGroups,
                            filters.amongWomenFilter
                        )}
                        onSelect={handleSelect}
                        filterName="amongWomenFilter"
                    />
                    shows those that
                </div>
                <div tw="flex items-center gap-[0.5rem] flex-wrap">
                    live within
                    <Dropdown
                        options={filterOptions(
                            distances,
                            filters.liveWithinFilter
                        )}
                        currentValue={currentValueName(
                            distances,
                            filters.liveWithinFilter
                        )}
                        onSelect={handleSelect}
                        filterName="liveWithinFilter"
                    />
                    from
                    <Dropdown
                        options={filterOptions(agents, filters.storeTypeFilter)}
                        currentValue={currentValueName(
                            agents,
                            filters.storeTypeFilter
                        )}
                        onSelect={handleSelect}
                        filterName="storeTypeFilter"
                    />
                    and live
                </div>
                <div tw="flex items-center gap-[0.5rem] flex-wrap">
                    in areas that are
                    <Dropdown
                        options={filterOptions(
                            regionGroups,
                            filters.areaFilter
                        )}
                        currentValue={currentValueName(
                            regionGroups,
                            filters.areaFilter
                        )}
                        onSelect={handleSelect}
                        filterName="areaFilter"
                    />
                </div>
                <div tw="flex items-center gap-[0.5rem] pt-[0.5rem]">
                    <input
                        id="showAgentTotals"
                        name="showAgentTotals"
                        tw="mr-[0.5rem] w-[1.5rem] h-[1.5rem]"
                        type="checkbox"
                        checked={filters.showAgentTotals}
                        onChange={handleCheckbox}
                    />
                    <label htmlFor="showAgentTotals">
                        Show only agent totals
                    </label>
                </div>
            </div>
            <div tw="bg-lightBlue-100 p-[0 1rem 1rem] w-full h-full">
                <h2 tw="py-2 text-xs tracking-[5px]">GEOGRAPHY</h2>
                <div tw="grid grid-cols-[repeat(2, 1fr)] gap-[1rem]">
                    <Dropdown
                        options={filterOptions(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        currentValue={currentValueName(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        onSelect={handleSelect}
                        filterName="geographyFilterRegionId"
                    />
                    <Dropdown
                        options={filterDistricts(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        currentValue={currentValueName(
                            filterDistricts(
                                regionsDistricts,
                                filters.geographyFilterRegionId
                            ),
                            filters.geographyFilterDistrictId
                        )}
                        onSelect={handleSelect}
                        filterName="geographyFilterDistrictId"
                    />
                </div>
                {!isLoadingChart && (
                    <InfoTables
                        title="WOMEN IN SELECTED FILTERS"
                        tableData={{
                            womenSelectedDistrict,
                            womenSelectedDivision,
                            totalWomen
                        }}
                        regionId={currentValueName(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        districtId={currentValueName(
                            filterDistricts(
                                regionsDistricts,
                                filters.geographyFilterRegionId
                            ),
                            filters.geographyFilterDistrictId
                        )}
                    />
                )}
            </div>
        </div>
    );
};

export default Channels;
