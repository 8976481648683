import { UseActiveSectionTypes } from "types/use-active-section.types";
import { sectionButtons } from "utils/items";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Header = ({
    activeSection,
    handleActiveSection
}: UseActiveSectionTypes) => (
    <header tw="p-[0.7rem 1rem] gap-[1rem] flex-wrap flex justify-between items-center w-full bg-blue-1000">
        <h1 tw="font-light tracking-[0.4rem] text-white-0">
            PAKISTAN NUTRITION PLATFORM
            <span tw="block text-sm tracking-[0rem] text-blue-1010">
                by World Data Lab for the Gates Foundation
            </span>
        </h1>
        <nav tw="flex justify-between gap-[1rem] text-sm flex-wrap">
            {sectionButtons.map(({ id, name }) => (
                <button
                    key={id}
                    onClick={() => handleActiveSection(id)}
                    css={[
                        tw`bg-gray-50 hover:bg-gray-100
                            hover:underline p-[1rem] w-[11rem] rounded-[0.25rem] tracking-[0.05rem]`,
                        activeSection === id &&
                            tw`font-bold bg-lightBlue-1000 hover:bg-lightBlue-1000`
                    ]}
                >
                    {name}
                </button>
            ))}
        </nav>
    </header>
);

export default Header;
