import InfoTables from "components/Table/InfoTables";
import RankTables from "components/Table/RankTables";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ImpactTables = ({ tableData }: { tableData: any }) => (
    <div
        tw="max-h-[calc(100vh - 4.5rem)] overflow-y-scroll p-[1rem]"
        className="scrollable"
    >
        <InfoTables
            title={"WOMEN IN SELECTED FILTERS"}
            tableData={tableData.impactData}
        />
        <RankTables
            title={"TOP 5 URBAN DISTRICTS"}
            tableData={tableData.topUrbanDistricts}
        />
        <RankTables
            title={"TOP 5 RURAL DISTRICTS"}
            tableData={tableData.topRuralDistricts}
        />
    </div>
);

export default ImpactTables;
