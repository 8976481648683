import { LatLngTuple } from "leaflet";

export const centerMap: LatLngTuple = [30, 68];

export const demandStyle = (value: number) => {
    const fillColor =
        value >= 20
            ? "#080749"
            : value >= 15
            ? "#2B235E"
            : value >= 10
            ? "#4F4579"
            : value >= 5
            ? "#746D97"
            : value > 0
            ? "#9A95B3"
            : "#BDBED0";

    return fillColor;
};

export const channelsStyle = (value: number) => {
    const fillColor =
        value >= 20
            ? "#1A6AFF"
            : value >= 15
            ? "#477FFE"
            : value >= 10
            ? "#5F94FD"
            : value >= 5
            ? "#71ABFC"
            : value > 0
            ? "#80C3FA"
            : "#A8E3FA";

    return fillColor;
};

export const targetingStyle = (
    pregnantWomenAreUndernourished: number,
    pregnantWomenLiveWithin: number
) => {
    const fillColor =
        pregnantWomenAreUndernourished > 8 && pregnantWomenLiveWithin > 66
            ? "#141228"
            : pregnantWomenAreUndernourished > 8 && pregnantWomenLiveWithin > 33
            ? "#821F28"
            : pregnantWomenAreUndernourished > 8 && pregnantWomenLiveWithin < 33
            ? "#DA2828"
            : pregnantWomenAreUndernourished > 2 && pregnantWomenLiveWithin > 66
            ? "#173D93"
            : pregnantWomenAreUndernourished > 2 && pregnantWomenLiveWithin > 33
            ? "#846893"
            : pregnantWomenAreUndernourished > 2 && pregnantWomenLiveWithin < 33
            ? "#E08B93"
            : pregnantWomenAreUndernourished <= 2 &&
              pregnantWomenLiveWithin > 66
            ? "#1560EA"
            : pregnantWomenAreUndernourished <= 2 &&
              pregnantWomenLiveWithin > 33
            ? "#89A4EA"
            : "#E7DCEA";

    return fillColor;
};

export const handleColor = (
    activeSection: string,
    value: number,
    pregnantWomenAreUndernourished?: number,
    pregnantWomenLiveWithin?: number
) =>
    activeSection === "demand"
        ? demandStyle(value)
        : activeSection === "channels"
        ? channelsStyle(value)
        : targetingStyle(
              pregnantWomenAreUndernourished!,
              pregnantWomenLiveWithin!
          );
