import SquareLegend from "components/Map/Legend/Square";
import Dropdown from "components/Dropdown";
import CustomSelect from "components/Select";
import { regionGroups, distances, wealthGroups } from "utils/items";
import { currentValueName, filterOptions } from "utils/utilities";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Demand = ({
    handleSelect,
    filters,
    kilometers
}: {
    handleSelect: any;
    filters: any;
    kilometers: string;
}) => (
    <div tw="flex flex-col items-center gap-[1rem] p-[1rem] text-sm">
        <div tw="flex items-center gap-[0.5rem] flex-wrap">
            Show pregnant undernourished women in
            <CustomSelect
                options={wealthGroups}
                currentValue={filters.wealthFilter}
                handleSelect={handleSelect}
                filterName="wealthFilter"
            />
            wealth quiltiles
        </div>
        <div tw="flex items-center gap-[0.5rem] flex-wrap">
            that live within
            <Dropdown
                options={filterOptions(distances, filters.liveWithinFilter)}
                currentValue={currentValueName(
                    distances,
                    filters.liveWithinFilter
                )}
                onSelect={handleSelect}
                filterName="liveWithinFilter"
            />
            in areas that are
            <Dropdown
                options={filterOptions(regionGroups, filters.areaFilter)}
                currentValue={currentValueName(
                    regionGroups,
                    filters.areaFilter
                )}
                onSelect={handleSelect}
                filterName="areaFilter"
            />
        </div>
        <SquareLegend
            kilometers={distances.find(({ id }) => id === kilometers)!.name}
        />
    </div>
);

export default Demand;
