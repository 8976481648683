import { CommonKeysPair } from "types/common-key-pair.types";

export const filterOptions = (array: any, activeValue: string) =>
    array.filter(({ id }: CommonKeysPair) => id !== activeValue);

export const filterDistricts = (array: any, activeValue: string) =>
    array.filter(({ id }: CommonKeysPair) => id === activeValue)[0].districts;

export const currentValueName = (array: any, activeValue: string) =>
    array.find(({ id }: CommonKeysPair) => id === activeValue)?.name;
