import { Mark } from "@mui/base";
import { useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { SliderTooltipProps } from "types/slider.types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

let marks: Mark[] = [];

for (let i = 0; i <= 100; i += 5) {
    if (i % 10 === 0) marks.push({ label: `${i}`, value: i });
    else marks.push({ label: " ", value: i });
}

const TooltipComponent = ({
    activeValue,
    handlePrev,
    handleNext,
    children,
    handleInput,
    name
}: SliderTooltipProps) => {
    const [showInput, setShowInput] = useState(false);

    return (
        <>
            <div
                tw="absolute bottom-[1rem]"
                style={{ left: `${activeValue - 13.25}%` }}
                onMouseDown={(e) => e.stopPropagation()}
            >
                <div tw="flex-col">
                    <div tw="flex flex-row border-[1px] border-black h-10 rounded-lg mb-2">
                        <div tw="flex flex-col w-6 items-center justify-center bg-white-0 rounded-l-lg cursor-pointer text-black hover:bg-gray-100 active:bg-blue-300">
                            <ArrowLeftIcon onClick={() => handlePrev(name)} />
                        </div>
                        <div tw="flex flex-col items-center justify-center opacity-100 bg-white-0 w-10">
                            <p
                                tw="absolute text-black w-6"
                                onClick={() => setShowInput(true)}
                            >
                                {activeValue}
                            </p>
                            {showInput && (
                                <input
                                    autoFocus={true}
                                    tw="text-black w-6 bg-white-0 absolute"
                                    onChange={(e) =>
                                        handleInput(name, e.target.value)
                                    }
                                    onBlur={(e) => {
                                        handleInput(name, e.target.value);
                                        setShowInput(false);
                                    }}
                                />
                            )}
                        </div>
                        <div tw="flex flex-col w-5 bg-white-0 items-center justify-center rounded-r-lg cursor-pointer text-black hover:bg-gray-100 active:bg-blue-300">
                            <ArrowRightIcon onClick={() => handleNext(name)} />
                        </div>
                    </div>
                    <div tw="flex flex-row items-start justify-center text-black">
                        <ArrowDropDownIcon tw="absolute h-8 top-[1.6rem]" />
                    </div>
                </div>
            </div>
            {children}
        </>
    );
};

const StyledSlider = styled(Slider)(() => ({
    ".MuiSlider-mark": {
        borderRadius: 0,
        height: "0.5rem",
        width: "0.1rem",
        marginTop: "0.25rem",
        paddingBottom: "0",
        backgroundColor: "black"
    },
    ".MuiSlider-markLabel": {
        fontSize: "0.65rem",
        fontWeight: 300
    },
    ".MuiSlider-rail": {
        color: "black",
        height: "1px",
        opacity: 1
    },
    ".MuiSlider-thumb": {
        height: ".7rem",
        width: ".7rem",
        backgroundColor: tw`bg-black`
    }
}));

const SliderComponent = ({
    activeValue,
    handleNext,
    handlePrev,
    handleInput,
    name
}: SliderTooltipProps) => (
    <StyledSlider
        track={false}
        getAriaLabel={() => "Slider marks"}
        getAriaValueText={(value) => value.toString()}
        marks={marks}
        min={0}
        max={100}
        onChange={(_, value) => handleInput(name, value)}
        value={activeValue}
        valueLabelDisplay="on"
        components={{
            ValueLabel: ({ value, children }) => (
                <TooltipComponent
                    activeValue={value}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    handleInput={handleInput}
                    children={children}
                    name={name}
                />
            )
        }}
    />
);

export default SliderComponent;
