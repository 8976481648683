import { InfoTablesProps } from "types/common-table.types";
import { TableRow } from "types/table.types";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const RankTables = ({ title, tableData }: InfoTablesProps) => {
    const rows: TableRow[] = Object.values(tableData).map((item: any) => ({
        label: item.name,
        value: item.score
    }));

    return (
        <div tw="flex flex-col">
            <h2 tw="leading-[16px] py-2 text-xs tracking-[5px] uppercase">
                {title}
            </h2>
            <div tw="grid grid-cols-[20% 60% 20%] items-center leading-[16px] text-[10px]">
                <span>Rank</span>
                <span>Districts</span>
                <span tw="text-right">Score</span>
            </div>
            {rows.map(({ label, value }, i) => (
                <div
                    tw="grid grid-cols-[20% 60% 20%] items-center py-2 leading-[20px] text-sm border-b-[0.5px] border-gray-200"
                    key={label}
                >
                    <p>{i + 1}</p>
                    <p>{label}</p>
                    <p tw="text-right text-blue-400 font-bold">
                        {value?.toLocaleString("en-US")}
                    </p>
                </div>
            ))}
        </div>
    );
};

export default RankTables;
