import SliderComponent from "components/Slider";
import NumberInput from "components/Input";
import Dropdown from "components/Dropdown";
import { currentValueName } from "utils/utilities";
import { ageGroups, wealthGroups, regionsDistricts } from "utils/items";
import CustomSelect from "components/Select";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const ImpactFilters = ({
    handleSelect,
    handleCheckbox,
    handleInput,
    handlePrev,
    handleNext,
    filters
}: {
    handleSelect: any;
    handleCheckbox: any;
    handleInput: any;
    handlePrev: any;
    handleNext: any;
    filters: any;
}) => (
    <div
        tw="flex flex-col max-h-[calc(100vh - 4.5rem)] overflow-y-scroll overflow-x-hidden p-[1rem] gap-[0.5rem]"
        className="scrollable"
    >
        <h2 tw="flex flex-row text-xs tracking-[5px]">AGE GROUPS</h2>
        <CustomSelect
            options={ageGroups}
            currentValue={filters.ageGroupFilters}
            handleSelect={handleSelect}
            filterName="ageGroupFilters"
        />
        <h2 tw="flex flex-row text-xs tracking-[5px]">WEALTH QUINTILES</h2>
        <CustomSelect
            options={wealthGroups}
            currentValue={filters.wealthFilter}
            handleSelect={handleSelect}
            filterName="wealthFilter"
        />
        <h2 tw="flex flex-row text-xs tracking-[5px]">DISTRICTS</h2>
        <Dropdown
            options={
                regionsDistricts.flatMap((region) => region.districts) as any
            }
            currentValue={currentValueName(
                regionsDistricts.flatMap((region) => region.districts) as any,
                filters.geographyFilterDistrictId
            )}
            onSelect={handleSelect}
            filterName="geographyFilterDistrictId"
        />
        <div tw="flex items-center gap-[0.5rem] pt-[0.5rem] mb-[1rem]">
            <input
                id="includeLactatingWomen"
                name="includeLactatingWomen"
                tw="mr-[0.5rem] w-[1.5rem] h-[1.5rem]"
                type="checkbox"
                checked={filters.includeLactatingWomen}
                onChange={handleCheckbox}
            />
            <label htmlFor="includeLactatingWomen">
                Include lactating women estimates
            </label>
        </div>
        <h2 tw="flex flex-row mb-[2rem] text-xs tracking-[5px]">
            COVERAGE (%)
        </h2>
        <SliderComponent
            activeValue={filters.coverage}
            handleInput={handleInput}
            handlePrev={handlePrev}
            handleNext={handleNext}
            name="coverage"
        />
        <h2 tw="flex flex-row mb-[2rem] text-xs tracking-[5px]">
            SUCCESS RATE (%)
        </h2>
        <SliderComponent
            activeValue={filters.successRate}
            handleInput={handleInput}
            handlePrev={handlePrev}
            handleNext={handleNext}
            name="successRate"
        />
        <h2 tw="flex flex-row text-xs mb-[2rem] tracking-[5px]">
            URBAN CLASSIFICATION <br />
            THRESHOLD (MIN POP %)
        </h2>
        <SliderComponent
            activeValue={filters.urbanClassification}
            handleInput={handleInput}
            handlePrev={handlePrev}
            handleNext={handleNext}
            name="urbanClassification"
        />
        <h2 tw="flex flex-row text-xs mb-[2rem] tracking-[5px]">
            ANTENATAL CARE ACCESS <br />
            (MIN POP %)
        </h2>
        <SliderComponent
            activeValue={filters.antenatalCareAccess}
            handleInput={handleInput}
            handlePrev={handlePrev}
            handleNext={handleNext}
            name="antenatalCareAccess"
        />
        <h2 tw="flex flex-row text-xs mb-[2rem] tracking-[5px]">
            UNITS PER MONTH <br /> (PER COVERED PLW)
        </h2>
        <SliderComponent
            activeValue={filters.unitsPerMonth}
            handleInput={handleInput}
            handlePrev={handlePrev}
            handleNext={handleNext}
            name="unitsPerMonth"
        />
        <h2 tw="flex flex-row text-xs mb-[2rem] tracking-[5px]">
            COST PER UNIT (PKR)
        </h2>
        <SliderComponent
            activeValue={filters.costPerUnit}
            handleInput={handleInput}
            handlePrev={handlePrev}
            handleNext={handleNext}
            name="costPerUnit"
        />
        <h2 tw="flex flex-row text-xs mt-[1.5rem] tracking-[3px]">
            WEIGHTED DISTRICT RANKING =
        </h2>
        <NumberInput
            currentValue={filters.xProgramReach}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleInput={handleInput}
            name="xProgramReach"
        />
        <h2 tw="flex flex-row text-xs mt-[1.5rem] tracking-[3px]">
            X PROGRAM REACH +
        </h2>
        <NumberInput
            currentValue={filters.caseAverted}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleInput={handleInput}
            name="caseAverted"
        />
        <h2 tw="flex flex-row text-xs mt-[1.5rem] tracking-[3px]">
            CASE AVERTED +
        </h2>
        <NumberInput
            currentValue={filters.antenatalCare}
            handlePrev={handlePrev}
            handleNext={handleNext}
            handleInput={handleInput}
            name="antenatalCare"
        />
        <h2 tw="flex flex-row text-xs tracking-[3px]">ANTENATAL CARE</h2>
    </div>
);

export default ImpactFilters;
