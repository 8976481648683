import { BarChart } from "@mui/x-charts/BarChart";
import { ChartDataTypes } from "types/table-chart-data.types";

const valueFormatter = (value: number) => `${value}%`;

const Chart = ({
    wealthUndernourishedPercentMap
}: {
    wealthUndernourishedPercentMap: ChartDataTypes;
}) => (
    <BarChart
        yAxis={[
            {
                id: "left",
                label: "% undernourished",
                labelStyle: {
                    fontWeight: "bolder"
                },
                valueFormatter,
                tickLabelStyle: {
                    fontSize: "12px"
                }
            }
        ]}
        xAxis={[
            {
                id: "bottom",
                data: Object.keys(wealthUndernourishedPercentMap!),
                scaleType: "band",
                label: "wealth quantiles",
                labelStyle: {
                    fontWeight: "bolder"
                },
                tickLabelStyle: {
                    fontSize: "12px"
                }
            }
        ]}
        leftAxis={"left"}
        rightAxis={null}
        bottomAxis={"bottom"}
        series={[
            {
                data: Object.values(wealthUndernourishedPercentMap!),
                valueFormatter,
                color: "#001533"
            }
        ]}
        height={270}
    />
);

export default Chart;
