/** @jsxImportSource @emotion/react */
import "twin.macro";

export const BarLegend = () => (
    <div tw="absolute bottom-4 left-4 rounded shadow-md p-[1rem] flex flex-col gap-[0.5rem] z-[1006] bg-white-0 max-w-[10rem]">
        <p tw="text-gray-550 font-bold">% undernourished</p>
        <div tw="flex">
            <div tw="bg-gradient-to-t from-demand-1000 via-demand-500 to-demand-0 h-[10rem] w-5"></div>
            <div tw="h-[10rem] flex flex-col gap-[0.5rem] font-regular text-gray-550 mt-[-0.7rem]">
                <span>- 0%</span>
                <span>- 5%</span>
                <span>- 10%</span>
                <span>- 15%</span>
                <span>- 20%</span>
            </div>
        </div>
    </div>
);

export const ChannelsLegend = ({ kilometers }: { kilometers: string }) => (
    <div tw="absolute bottom-4 left-4 rounded shadow-md p-[1rem] flex flex-col gap-[0.5rem] z-[1006] bg-white-0 max-w-[10rem]">
        <p tw="text-gray-550 font-bold">
            % within {kilometers} of any agent type
        </p>
        <div tw="flex">
            <div tw="bg-gradient-to-t from-channels-1020 via-channels-1010 to-channels-1000 h-[10rem] w-5"></div>
            <div tw="h-[10rem] flex flex-col gap-[0.5rem] font-regular text-gray-550 mt-[-0.7rem]">
                <span>- 0%</span>
                <span>- 20%</span>
                <span>- 40%</span>
                <span>- 60%</span>
                <span>- 80%</span>
            </div>
        </div>
    </div>
);
