import { BaseSyntheticEvent, useCallback, useState } from "react";
import { DropdownTypes } from "types/dropdown.types";
import ArrowDown from "assets/images/arrow_down.svg";
import ArrowUp from "assets/images/arrow_up.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const Dropdown = ({
    options,
    currentValue,
    onSelect,
    filterName
}: DropdownTypes) => {
    const [isDown, setIsDown] = useState(false);

    const handleChangeCallback = useCallback(
        (e: BaseSyntheticEvent) => {
            onSelect(e);
            setIsDown(false);
        },
        [onSelect]
    );

    const handleToggle = () => setIsDown(!isDown);

    return (
        <div tw="relative">
            <div tw="h-full flex flex-col justify-between pb-1 px-1 rounded bg-gray-100">
                <button
                    tw="flex justify-between items-center border-b-2 border-blue-500 mb-1 mx-1 font-bold"
                    onClick={handleToggle}
                >
                    {currentValue}
                    <img
                        src={isDown ? ArrowUp : ArrowDown}
                        alt="arrow"
                        tw="self-center"
                    />
                </button>
            </div>
            <ul
                css={[
                    tw`hidden max-h-[14rem] overflow-y-scroll top-[1.5rem] w-max absolute drop-shadow-2xl flex-col bg-white-0 rounded z-10`,
                    isDown && tw`flex`
                ]}
            >
                {options.map(({ id, name }) => (
                    <li
                        key={id}
                        tw="hover:bg-blue-200 hover:rounded cursor-pointer"
                    >
                        <button
                            tw="p-[0.5rem] w-full text-left"
                            onClick={handleChangeCallback}
                            id={id}
                            name={filterName}
                        >
                            {name}
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Dropdown;
