import InfoTables from "components/Table/InfoTables";
import Chart from "components/Main/Demand/Chart";
import Dropdown from "components/Dropdown";
import {
    womenDemandGroups,
    ageGroups,
    wealthGroups,
    regionGroups,
    regionsDistricts
} from "utils/items";
import { ChartDataTypes, TableDataTypes } from "types/table-chart-data.types";
import CustomSelect from "components/Select";
import {
    currentValueName,
    filterDistricts,
    filterOptions
} from "utils/utilities";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const Demand = ({
    handleSelect,
    filters,
    isLoadingChart,
    data
}: {
    handleSelect: any;
    filters: any;
    isLoadingChart: boolean;
    data: TableDataTypes;
}) => {
    const {
        womenSelectedDistrict,
        womenSelectedDivision,
        totalWomen,
        wealthUndernourishedPercentMap
    } = data;

    return (
        <div tw="grid grid-rows-[auto 1fr] items-center h-full text-sm">
            <div tw="p-[1rem]">
                <div tw="flex items-center gap-[0.5rem] flex-wrap">
                    Among
                    <Dropdown
                        options={filterOptions(
                            womenDemandGroups,
                            filters.amongWomenFilter
                        )}
                        currentValue={currentValueName(
                            womenDemandGroups,
                            filters.amongWomenFilter
                        )}
                        onSelect={handleSelect}
                        filterName="amongWomenFilter"
                    />
                    in
                    <CustomSelect
                        options={ageGroups}
                        currentValue={filters.ageGroupFilters}
                        handleSelect={handleSelect}
                        filterName="ageGroupFilters"
                    />
                    age group
                </div>
                <div tw="flex items-center gap-[0.5rem] flex-wrap">
                    and in
                    <CustomSelect
                        options={wealthGroups}
                        currentValue={filters.wealthFilter}
                        handleSelect={handleSelect}
                        filterName="wealthFilter"
                    />
                    wealth quiltiles , show those that are undernourished
                </div>
                <div tw="flex items-center gap-[0.5rem] flex-wrap">
                    and live in areas that are
                    <Dropdown
                        options={filterOptions(
                            regionGroups,
                            filters.areaFilter
                        )}
                        currentValue={currentValueName(
                            regionGroups,
                            filters.areaFilter
                        )}
                        onSelect={handleSelect}
                        filterName="areaFilter"
                    />
                </div>
            </div>
            <div tw="bg-lightBlue-100 p-[0 1rem 1rem] w-full h-full">
                <h2 tw="py-2 text-xs tracking-[5px]">GEOGRAPHY</h2>
                <div tw="grid grid-cols-[repeat(2, 1fr)] gap-[1rem]">
                    <Dropdown
                        options={filterOptions(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        currentValue={currentValueName(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        onSelect={handleSelect}
                        filterName="geographyFilterRegionId"
                    />
                    <Dropdown
                        options={filterDistricts(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        currentValue={currentValueName(
                            filterDistricts(
                                regionsDistricts,
                                filters.geographyFilterRegionId
                            ),
                            filters.geographyFilterDistrictId
                        )}
                        onSelect={handleSelect}
                        filterName="geographyFilterDistrictId"
                    />
                </div>
                {!isLoadingChart && wealthUndernourishedPercentMap && (
                    <Chart
                        wealthUndernourishedPercentMap={
                            wealthUndernourishedPercentMap as ChartDataTypes
                        }
                    />
                )}
                {!isLoadingChart && (
                    <InfoTables
                        title="WOMEN IN SELECTED FILTERS"
                        tableData={{
                            womenSelectedDistrict,
                            womenSelectedDivision,
                            totalWomen
                        }}
                        regionId={currentValueName(
                            regionsDistricts,
                            filters.geographyFilterRegionId
                        )}
                        districtId={currentValueName(
                            filterDistricts(
                                regionsDistricts,
                                filters.geographyFilterRegionId
                            ),
                            filters.geographyFilterDistrictId
                        )}
                    />
                )}
            </div>
        </div>
    );
};

export default Demand;
