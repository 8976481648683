import Select from "@mui/material/Select";
import { SelectTypes } from "types/select.types";
import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
/** @jsxImportSource @emotion/react */
import { theme } from "twin.macro";

const CustomSelectStyle = styled(Select)<any>(() => ({
    ".MuiInputBase-input": {
        color: theme`colors.blue.700`
    },
    ".MuiSelect-icon": {
        display: "none"
    }
}));

const CustomSelect = ({
    options,
    currentValue,
    handleSelect,
    filterName
}: SelectTypes) => {
    const handleOptions = () =>
        options.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
                {name}
            </MenuItem>
        ));

    return (
        <CustomSelectStyle
            tw="border-b-2 border-blue-500 rounded-none h-[1rem] pb-[0.25rem] !w-auto"
            onChange={handleSelect}
            name={filterName}
            value={currentValue}
            multiple
        >
            {handleOptions()}
        </CustomSelectStyle>
    );
};

export default CustomSelect;
