import lgnd from "assets/images/lgnd.svg";
/** @jsxImportSource @emotion/react */
import "twin.macro";

const SquareLegend = ({ kilometers }: { kilometers: string }) => (
    <div tw="flex w-[330px] items-center">
        <img src={lgnd} alt="Square Legend" />
        <p
            tw="text-[#909191] text-xs rotate-180 bg-[#f1f1f1] ml-[-1rem] mt-[-1rem]"
            style={{ writingMode: "vertical-rl" }}
        >
            % living within {kilometers} of any agent type
        </p>
    </div>
);

export default SquareLegend;
