import { TooltipInfo } from "types/tooltip-info.types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const ToolTip = ({ tooltipInfo }: TooltipInfo) => (
    <div
        css={[
            tw`flex transition duration-300 top-0 rounded-[0.2rem] bg-blue-800 p-[1rem] max-w-[17rem] w-full text-white-0 flex-col gap-[1.5rem] fixed z-[1001]`
        ]}
        style={{
            left: `${tooltipInfo.originalEvent.clientX - 100}px`,
            top: `${tooltipInfo.originalEvent.clientY - 160}px`
        }}
    >
        <p tw="font-bold leading-4">
            {tooltipInfo.target.feature.divisionName}
        </p>
        <p tw="inline font-sans text-sm leading-4">
            <span tw="text-blue-1010 font-bold">
                {tooltipInfo.target.feature.filterData.mapWomenData.filteredWomenAmount.toLocaleString(
                    "en-US"
                )}{" "}
                (
                {tooltipInfo.target.feature.filterData.mapWomenData.filteredWomenPercent.toLocaleString(
                    "en-US"
                )}
                %)
            </span>
            <span tw="text-white-0"> undernourished out of </span>
            <span tw="text-blue-1010 font-bold">
                {tooltipInfo.target.feature.filterData.mapWomenData.totalWomenAmount.toLocaleString(
                    "en-US"
                )}{" "}
            </span>
            <span tw="text-white-0">pregnant women</span>
        </p>
        <div tw="text-xs leading-4">
            <p>
                sample size ={" "}
                {tooltipInfo.target.feature.filterData.mapWomenData.sampleSize}
            </p>
        </div>
    </div>
);

export default ToolTip;
