import { NumberInputChildren } from "types/number-input.types";
import Minus from "assets/images/minus.svg";
import Plus from "assets/images/plus.svg";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const NumberInput = ({
    currentValue,
    handleNext,
    handlePrev,
    handleInput,
    name
}: NumberInputChildren) => {
    return (
        <article tw="flex flex-row justify-between border-b-2 border-blue-500">
            <button
                css={[
                    tw`h-full flex flex-row justify-center content-center`,
                    currentValue <= 0.5 && tw`opacity-10`
                ]}
                onClick={() => handlePrev(name, true)}
                disabled={currentValue <= 0.5}
            >
                <img src={Minus} alt="minus" />
            </button>
            <input
                type="number"
                value={currentValue}
                onChange={(e) => handleInput(name, e.target.value)}
                tw="text-center font-bold bg-gray-100"
            />
            <button
                css={[
                    tw`h-full flex flex-row justify-center content-center`,
                    currentValue >= 99.5 && tw`opacity-10`
                ]}
                onClick={() => handleNext(name, true)}
                disabled={currentValue >= 99.5}
            >
                <img src={Plus} alt="plus" />
            </button>
        </article>
    );
};

export default NumberInput;
