import { ColumnsChildren } from "types/column-layout.types";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const LayoutGrid = ({
    leftColumn,
    middleColumn,
    rightColumn
}: ColumnsChildren) => (
    <section
        css={[
            tw`w-full h-[calc(100vh - 4.5rem)] grid grid-cols-[40% 60%] overflow-hidden`,
            middleColumn && tw`grid-cols-[25% 50% 25%]`
        ]}
    >
        <article tw="bg-gray-100">{leftColumn}</article>
        {middleColumn && <article tw="bg-gray-50">{middleColumn}</article>}
        <article css={[middleColumn ? tw`bg-lightBlue-100` : tw`bg-gray-50`]}>
            {rightColumn}
        </article>
    </section>
);

export default LayoutGrid;
